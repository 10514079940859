import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty } from 'vant'
import { Popup, Toast, Dialog } from 'vant'
import { Form } from 'vant'
import { Cascader } from 'vant'
import role from '../../assets/lib/role'
import { getCheckCode } from '../../api/registerApi'
import thearea from '../../assets/lib/area.js'


Vue.use(Cascader)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Dialog)
export default {
  name: 'editClaim',
  components: {},
  data () {
    return {
      showPopover: false,
      showPopover1: false,
      isget: true,
      second: 60,//是否获取验证码
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      showPicker: false,
      showPicker1: false,
      showPicker2: false,
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      fieldValue: '',
      show: false,
      options: [],
      ClaimsDetail: {
        bxClaimsOrderNo: '',//保单号
        bxClaimsUserId: '',//用户ID
        bxClaimsShopId: '',//保险产品ID
        bxClaimsShopName: '',//保险产品名称
        bxClaimsCompanyId: '',//保险公司Id
        bxClaimsCompanyName: '',//保险公司名称

        bxClaimsInsurantName: '',//被保人姓名
        bxClaimsInsurantCerType: '',//被保人证件类型
        bxClaimsInsurantCerNo: '',//被保人证件号
        bxClaimsInsurantGender: '',//被保人性别(1男 2女)

        bxClaimsInformantName: '',//报案人姓名
        bxClaimsInformantPhone: '',//报案人手机号
        bxClaimsInformantCode: '',//手机号验证码
        bxClaimsDangerCause: '',//出险原因代码
        bxClaimsDangerCauseValue: '',//出险原因内容
        cascaderValue: '',//出险地区
        provinceCode: '',//省代码
        cityCode: '',//市代码
        areaCode: '',//区代码
        province: '',//省
        city: '',//市
        area: '',//区
        bxClaimsTotalPrice: '',//合计理赔金额
        bxClaimsInsuranceTime: '',//出险时间
        bxClaimsDetailAddress: '',//详细地址
        bxClaimsInsuranceGoby: '',//出险经过

        bxClaimsOpenBank: '',//开户行
        bxClaimsBankNo: '',//银行账号
        height1: '',
        height2: '',

      },
      causeList: [

      ],


    }
  },
  beforeCreate () {

  },
  created () {
    this.options = thearea
    this.getDictionary('bx_claims_danger_cause')
    if (this.$route.query.isdetail && this.$route.query.isdetail == 1) {
      this.claimsDetail({ id: this.$route.query.bxClaimsId }).then(res => {
        if (res.code == 200) {
          this.ClaimsDetail = res.data
          this.minDate = new Date(res.data.beginDate)
          this.maxDate = new Date(res.data.endDate)
          this.ClaimsDetail.cascaderValue = res.data.province + res.data.city + res.data.area
          this.ClaimsDetail.bxClaimsDangerCauseValue = this.causeList.filter(item => {
            return item.value == this.ClaimsDetail.bxClaimsDangerCause
          })[0].text
          this.ClaimsDetail.bxClaimsInformantCode = ''
        }
      })

    } else {
      this.ClaimsDetail = this.$store.state.DetailList
    }





  },
  methods: {
    ...mapActions('claims', ['claimsCode', 'claimsVerCode', 'claimsDetail']),
    ...mapActions('libList', ['serchDictionary']),
    back () {
      this.$router.go(-1)

    },
    getDictionary (dictValue) {//获取字典出险原因
      var that = this
      this.serchDictionary({ dictType: dictValue }).then(res => {
        if (res.code == 200) {

          that.causeList = this.replaceName(res.data)
        }
      })

    },
    replaceName (arr) {
      var json = arr
      for (var i in arr) {
        for (var j in arr[i]) {
          if (j == 'dictValue') {
            json[i]['value'] = arr[i][j]  //修改属性名为“value”
            delete arr[i]['dictValue']         //删除“text”
          } else if (j == 'dictLabel') {
            json[i]['text'] = arr[i][j]  //修改属性名为“value”
            delete arr[i]['dictLabel']
          }
        }
      }
      return arr

    },
    changeOne () {
      this.showPopover = true

    },
    changeOne1 () {
      this.showPopover1 = true
    },
    onConfirm2 (value) {//出险时间
      var date = new Date(value)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      var h = date.getHours()
      h = h < 10 ? ('0' + h) : h
      var minute = date.getMinutes()
      minute = minute < 10 ? ('0' + minute) : minute
      var s = date.getSeconds()
      s = s < 10 ? ('0' + s) : s
      let time = y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
      this.ClaimsDetail.bxClaimsInsuranceTime = time
      this.showPicker2 = false
    },
    onConfirm1 (value) {//出险原因
      this.ClaimsDetail.bxClaimsDangerCauseValue = value.text
      this.ClaimsDetail.bxClaimsDangerCause = value.value
      this.showPicker1 = false
    },
    onFinish ({ selectedOptions }) {
      this.show = false
      this.ClaimsDetail.provinceCode = selectedOptions[0].value
      this.ClaimsDetail.cityCode = selectedOptions[selectedOptions.length - 2].value
      this.ClaimsDetail.areaCode = selectedOptions[selectedOptions.length - 1].value
      this.ClaimsDetail.province = selectedOptions[0].text//省
      this.ClaimsDetail.city = selectedOptions[selectedOptions.length - 2].text//市
      this.ClaimsDetail.area = selectedOptions[selectedOptions.length - 1].text//区
      this.ClaimsDetail.cascaderValue = selectedOptions.map((option) => option.text).join('/')
    },
    sub () {
      var flg = this.verification()
      if (flg) {
        this.claimsVerCode({ phone2: this.ClaimsDetail.bxClaimsInformantPhone, code: this.ClaimsDetail.bxClaimsInformantCode }).then((res) => {
          if (res.code == 200) {
            this.$store.commit('setdata', this.ClaimsDetail)
            this.$store.commit('setFile', this.ClaimsDetail.bxClaimsCertificateImgs == '' ? [] : this.ClaimsDetail.bxClaimsCertificateImgs.split(','))
            this.$store.commit('setFile1', this.ClaimsDetail.bxClaimsCaseHistoryImgs == '' ? [] : this.ClaimsDetail.bxClaimsCaseHistoryImgs.split(','))
            this.$store.commit('setFile2', this.ClaimsDetail.bxClaimsCostProveImgs == '' ? [] : this.ClaimsDetail.bxClaimsCostProveImgs.split(','))
            /////////////新增
            this.$store.commit('setFile3', this.ClaimsDetail.bxClaimsPathologicalDiagnosisReportImgs == '' ? [] : this.ClaimsDetail.bxClaimsPathologicalDiagnosisReportImgs.split(','))
            this.$store.commit('setFile4', this.ClaimsDetail.bxClaimsPrescriptionDrugImgs == '' ? [] : this.ClaimsDetail.bxClaimsPrescriptionDrugImgs.split(','))
            this.$store.commit('setFile5', this.ClaimsDetail.bxClaimsGeneticTestingReportImgs == '' ? [] : this.ClaimsDetail.bxClaimsGeneticTestingReportImgs.split(','))

            this.$router.push({ name: 'ayeditFlies', query: { iszan: 1 } })
          } else {
            Toast(res.msg)
          }


        })


      }
    },
    // 获取验证码
    getregVers () {
      const phone = this.ClaimsDetail.bxClaimsInformantPhone
      if (phone !== '') {
        if (!role.phone.test(phone)) {
          Toast('请输入正确手机号')
        } else {
          this.claimsCode({ phone1: phone }).then((res) => {
            if (res.code === 200) {
              Toast({
                message: '验证码已发送，请注意查收!',
              })
              this.isget = false
              this.nogetVer()
            } else {
              Toast(res.msg)
            }
          })
        }
      } else {
        Toast('手机号不能为空')
      }
    },
    // 获取验证码
    nogetVer () {
      const that = this
      this.setInnt = setInterval(() => {
        if (that.second > 1) {
          that.second -= 1
        } else {
          clearInterval(that.setInnt)
          that.isget = true
          that.second = 60
        }
      }, 1000)
    },
    //正则验证
    verification () {
      // 报案人姓名
      if (this.ClaimsDetail.bxClaimsInformantName == '') {
        Toast('报案人姓名不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsInformantName.length > 20 || this.ClaimsDetail.bxClaimsInformantName.length < 2) {
        Toast('请输入2-20位字符的报案人姓名')
        return false
      } else if (this.ClaimsDetail.bxClaimsInformantPhone == '') {//报案人手机号
        Toast('手机号不能为空')
        return false
      } else if (!role.phone.test(this.ClaimsDetail.bxClaimsInformantPhone)) {
        Toast('请输入正确手机号')
        return false
      } else if (this.ClaimsDetail.bxClaimsInformantCode == '' || this.ClaimsDetail.bxClaimsInformantCode == null || this.ClaimsDetail.bxClaimsInformantCode == undefined) {//报案人验证码
        Toast('验证码不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsDangerCause == '') {//出险原因
        Toast('请选择出险原因')
        return false
      } else if (this.ClaimsDetail.bxClaimsTotalPrice == '') {//花费金额
        Toast('花费金额不能为空')
        return false
      } else if (!role.kaMoney1.test(this.ClaimsDetail.bxClaimsTotalPrice)) {
        Toast('花费金额最多保留两位小数')
        return false
      } else if (this.ClaimsDetail.bxClaimsInsuranceTime == '') {//出险时间
        Toast('请选择出险时间')
        return false
      } else if (this.ClaimsDetail.cascaderValue == '') {//出险地区
        Toast('请选择出险地区')
        return false
      } else if (this.ClaimsDetail.bxClaimsDetailAddress == '') {//详细地址
        Toast('详细地址不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsDetailAddress.length > 255 || this.ClaimsDetail.bxClaimsDetailAddress.length < 2) {
        Toast('请输入2-85位字符的详细地址')
        return false
      } else if (this.ClaimsDetail.bxClaimsInsuranceGoby == '') {//出险经过
        Toast('出险经过不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsInsuranceGoby.length > 85 || this.ClaimsDetail.bxClaimsInsuranceGoby.length < 2) {
        Toast('请输入2-85位字符的出险经过')
        return false
      } else if (this.ClaimsDetail.bxClaimsOpenBank == '') {//开户银行
        Toast('开户银行不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsOpenBank.length > 50 || this.ClaimsDetail.bxClaimsOpenBank.length < 2) {
        Toast('请输入2-50位字符的开户银行')
        return false
      } else if (this.ClaimsDetail.bxClaimsBankNo == '') {//银行卡号
        Toast('银行卡号不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsBankNo.length > 30 || this.ClaimsDetail.bxClaimsBankNo.length < 9) {
        Toast('请输入9-30位字符的银行卡号')
        return false
      } else if (!role.cnum.test(this.ClaimsDetail.bxClaimsBankNo)) {
        Toast('请输入正确格式的银行卡号')
        return false
      } else {
        return true
      }

    },
    InformantName () { // 报案人姓名
      if (this.ClaimsDetail.bxClaimsInformantName == '') {
        Toast('报案人姓名不能为空')
      } else if (this.ClaimsDetail.bxClaimsInformantName.length > 20 || this.ClaimsDetail.bxClaimsInformantName.length < 2) {
        Toast('请输入2-20位字符的报案人姓名')
      }
    },
    InformantPhone () {//手机号
      if (this.ClaimsDetail.bxClaimsInformantPhone == '') {//报案人手机号
        Toast('手机号不能为空')
      } else if (!role.phone.test(this.ClaimsDetail.bxClaimsInformantPhone)) {
        Toast('请输入正确手机号')
      }
    },
    InformantCode () {//验证码
      if (this.ClaimsDetail.bxClaimsInformantCode == '' || this.ClaimsDetail.bxClaimsInformantCode == null || this.ClaimsDetail.bxClaimsInformantCode == undefined) {//报案人验证码
        Toast('验证码不能为空')
        return false
      }
    },
    DangerCause () {//出险原因
      if (this.ClaimsDetail.bxClaimsDangerCause == '') {//出险原因
        Toast('请选择出险原因')
        return false
      }
    },
    TotalPrice () {//花费金额
      if (this.ClaimsDetail.bxClaimsTotalPrice == '') {//花费金额
        Toast('花费金额不能为空')
        return false
      } else if (!role.kaMoney1.test(this.ClaimsDetail.bxClaimsTotalPrice)) {
        Toast('请输入正确格式的花费金额')
        return false
      }
    },
    InsuranceTime () {//出险时间
      if (this.ClaimsDetail.bxClaimsInsuranceTime == '') {
        Toast('请选择出险时间')
        return false
      }
    },
    cascaderValue1 () {//出险地区
      if (this.ClaimsDetail.cascaderValue == '') {
        Toast('请选择出险地区')
        return false
      }
    },
    DetailAddress () {//详细地址
      if (this.ClaimsDetail.bxClaimsDetailAddress == '') {
        Toast('详细地址不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsDetailAddress.length > 255 || this.ClaimsDetail.bxClaimsDetailAddress.length < 2) {
        Toast('请输入2-255位字符的详细地址')
        return false
      }
    },
    InsuranceGoby () {//出险经过
      if (this.ClaimsDetail.bxClaimsInsuranceGoby == '') {
        Toast('出险经过不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsInsuranceGoby.length > 85 || this.ClaimsDetail.bxClaimsInsuranceGoby.length < 2) {
        Toast('请输入2-85位字符的出险经过')
        return false
      }
    },
    OpenBank () {//开户银行
      if (this.ClaimsDetail.bxClaimsOpenBank == '') {
        Toast('开户银行不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsOpenBank.length > 50 || this.ClaimsDetail.bxClaimsOpenBank.length < 2) {
        Toast('请输入2-50位字符的开户银行')
        return false
      }
    },
    BankNo () {//银行卡号
      if (this.ClaimsDetail.bxClaimsBankNo == '') {
        Toast('银行卡号不能为空')
        return false
      } else if (this.ClaimsDetail.bxClaimsBankNo.length > 30 || this.ClaimsDetail.bxClaimsBankNo.length < 9) {
        Toast('请输入9-30位字符的银行卡号')
        return false
      } else if (!role.cnum.test(this.ClaimsDetail.bxClaimsBankNo)) {
        Toast('请输入正确格式的银行卡号')
        return false
      }
    },
    getInformantName () {//报案人姓名
      this.InformantName()
    },
    getInformantPhone () {//手机号
      this.InformantPhone()
    },
    getInformantCode () {//验证码
      this.InformantCode()
    },
    getDangerCauseValue () {//出险原因
      this.showPicker1 = false
      this.DangerCause()
    },
    getTotalPrice () {//花费金额
      this.TotalPrice()

    },
    getInsuranceTime () {//出险时间
      this.showPicker2 = false
      this.InsuranceTime()

    },
    getcascaderValue () {//出险地区
      this.show = false
      // this.cascaderValue1()
    },
    getDetailAddress () {//详细地址
      this.DetailAddress()
    },
    getInsuranceGoby () {//出险经过
      this.InsuranceGoby()
    },
    getOpenBank () {//开户银行
      this.OpenBank()

    },
    getBankNo () {//银行卡号
      this.BankNo()

    }









  },
}
